import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/max/Documents/node-projects/maxs-portfolio/src/components/page-layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I used `}<a parentName="p" {...{
        "href": "https://github.com/jeremyletang/rust-sfml"
      }}>{`rust-sfml`}</a>{` to create a simple rhythm game that can load StepMania (.sm) files. If you're unfamiliar with rhythm games, think of Guitar Hero, Rock Band, Dance Dance Revolution, etc. Essentially, the player has to hit an arrow key in time with "notes" that appear on the screen.`}</p>
    <h2>{`Demo`}</h2>
    <Video block src="/videos/rhythm%20game%20demo%201.mp4" mdxType="Video" /><Video block src="/videos/rhythm%20game%20demo%202.mp4" mdxType="Video" />
    <h2>{`Source`}</h2>
    <p>{`The source for my rhythm game is available `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/rust-learning/tree/RhythmGame"
      }}>{`on Github`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      